@import "~azure-maps-control/dist/atlas.css";
.slider0 {
  --sx: "30%";
}
.color-standard {
  background: #f7f7f7;
}
.color-alternative {
  color: #333333;
}
#agroMapContainer {
  width: 100%;
  height: 600px;
  /* min-width: 290px; */
  position: relative;
}

.customInfobox {
  /* width: auto; */
  display: table;
  max-width: 240px;
  padding: 10px;
  font-size: 12px !important;
}

.customInfobox .name {
  font-size: 12px !important;
  font-weight: bold;
  margin-bottom: 5px;
}
.map-copyright {
  visibility: hidden !important;
}
.textLineHight {
  line-height: 1.2;
}
#CustomerMap {
  height: 100% !important;
  width: 100% !important;
  position: relative;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #3dcd58;
  background-color: #fff;
  border-top-width: 0px !important;
  border-top-style: solid;
  border-top-color: #3dcd58;
}

.downarrow {
  position: fixed;
  box-sizing: border-box;
  height: 15px;
  width: 15px;
  border-style: solid;
  border-color: #333333;
  border-width: 0px 3px 3px 0px;
  transform: rotate(45deg);
  transition: border-width 150ms ease-in-out;
}

/* .nav-content > small{
  text-overflow: ellipsis !important;
  width: 10px !important;
} */
#CustomerMap .Map {
  height: 380px;
  width: 525px;
  position: absolute;
}
#CustomerMap .CreateLanMap {
  height: 380px;
  width: 525px;
  position: absolute;
}
.width8 {
  width: 8%;
}
.width10 {
  width: 10%;
}
.width12 {
  width: 12%;
}
.width16 {
  width: 16%;
}
.width18 {
  width: 18%;
}
.width26 {
  width: 26%;
}

.selectedBar {
  background-color: transparent !important;
}
.image-height {
  height: 380px;
  margin-top: 0px;
  width: 100%;
}
.realTimeEnergyTable {
  overflow: hidden;
  padding: 4px 6px;
  background-color: white;
  font-size: 12px;
}
.customerProfileSelect {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  width: 120%;
  display: block;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 4px;
  height: 28px;
}
.createNewUserInfo2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  background: white;
  margin: 0 auto;
}
.createNewUserInfo {
  justify-content: center;
  align-items: center;
  margin: auto;
  text-align: center;
}
.azureMapControlsStyle {
  width: 21vw;
  height: 40vh;
  overflow: hidden;
  border: 1px solid lightgray;
}
.azureMapControlsStyle2 {
  width: 100%;
  height: 100%;
}
.mt-30px {
  margin-top: 25px;
}
.error-border {
  border-color: red !important;
}
.valid-border {
  border-color: #9fa0a4;
}
.assestsInput {
  position: absolute;
  width: 100%;
  top: 5px;
  left: 0px;
  height: 29px;
  border: none;
  font-size: 13px;
  padding: 0px 15px;
}
.tooltip-info li {
  padding: 3px;
  font-size: 10px;
  font-weight: 100;
}
.tooltip-info strong {
  font-size: 9px;
}
.tooltip-info se-icon {
  font-size: 20px;
  width: 20px;
  cursor: pointer;
}
.tooltip-info .tooltip-container {
  width: 180px;
  height: 60px;
}
.dummyCss {
  visibility: hidden;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}
.fixed-height {
  height: 19px;
}
.fixed-line-height {
  line-height: 1.2;
}

.marginTop {
  margin-top: 0px;
}
@-moz-document url-prefix() {
  .dummyCss {
    border: none;
  }
}
.upload-file {
  display: block;
  border: 1px solid lightgray;
  width: 100%;
}
.Upload-info {
  margin-top: 5px;
  color: #737373;
}
.delete-icon {
  position: absolute;
  top: 3px;
  cursor: pointer;
}
.disableTableRow {
  background: #efefef;
}
.assestTable {
  /* overflow: hidden; */
}
.table-vertical-scroll {
  position: relative;
  width: 100%;
  overflow-wrap: anywhere;
}
.table-vertical-scroll .assestTable table {
  width: 145%;
}
.table-scroll {
  position: absolute;
  width: 100%;
  top: 24px;
}
.table-scroll ::-webkit-scrollbar-track:horizontal,
.table-scroll ::-webkit-scrollbar-thumb:horizontal {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 16px;
}
.table-scroll ::-webkit-scrollbar-track:horizontal {
  background-color: #e6e6e6;
  background-color: var(--se-divider, #e6e6e6);
}
.table-scroll ::-webkit-scrollbar:horizontal {
  height: 12px;
  width: 12px;
}
.assestTable table {
  width: 100%;
}
.assestTable table th,
td {
  padding: 8px 10px;
  line-height: 1.2;
  font-size: 14px;
}
.assestTable se-table-group-header {
  border: 1px solid lightgray;
  height: 35px;
}
.assestTable se-table-group,
.assestTable se-table-item {
  height: 35px;
}
.assestTable se-table-item {
  border-right: 1px solid lightgray;
}
.assestTable se-table-item:first-child {
  border-left: 1px solid lightgray;
}

.assestTable input:disabled {
  background: white;
}
.assestTable input:focus {
  border: none;
  outline: none;
}

/* #agroMapContainerControls{
  height: 20vh;
         width: 20vw;
} */
/* .sing-up input[type="text"]:disabled {
  color:#0F0F0F;
} */
.SWPHeader {
  overflow: hidden;
  min-height: 47px;
  height: auto;
  margin: 0px;
  padding: 10px;
}
.height20 {
  height: 20px;
}
.pointer {
  cursor: pointer !important;
}
.text {
  cursor: text !important;
}
.toggleBtn {
  position: relative;
  top: 4px;
}
.toggleBtn .iconBtnLeft {
  padding: 12px 5px 5px 5px;
  background: #ededed;
  cursor: pointer;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.toggleBtn .iconBtnRight {
  padding: 12px 5px 5px 5px;
  background: #ededed;
  cursor: pointer;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.toggleBtn .active {
  background: #2f3136;
  color: white;
}

.lp-0 {
  padding-left: 0px !important;
}

.pdf-download {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
  height: 80px;
}

/* .slectedItem{
  color: #fff;
  background-color: #626469;
}
.slectedItem small{
  color: #fff;
}
.descColor{
color: #9FA0A4;
} */
button .standard {
  color: #fff !important;
  background-color: #626469 !important;
}

.nav-content,
.list-item-label {
  font-size: 14px !important;
  line-height: 18px !important;
  color: #333333;
}

.nav-content {
  font-size: 12px;
  color: #333333 !important;
}
/* .nav-content, .small {font-size: 12px; text-transform: uppercase; color: #9fa0a4 !important;} */

/* Medium devices (Mobile, 768px and up) */
@media only screen and (max-width: 767px) {
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 339px;
    overflow-y: auto;
    padding-top: 6px;
  }

  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }

  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }

  .OverNotifiction {
    height: 230px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 11px;
    height: 100%;
    padding: 0px 0px;
  }
  .blockheight {
    line-height: 12px;
    padding: 12px 0px 8px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 30px 0px 26px 0px;
  }

  .font-normal {
    font-size: 13px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 24px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 15px;
    line-height: 15px;
    margin-bottom: 16px;
  }
  .font-size-18 {
    font-size: 13px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 13px !important;
    line-height: 24px;
    font-weight: 600;
    font-family: Nunito, Light;
    /*  */
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
    /*  */
  }
  .font-size-12 {
    font-size: 11px;
    /*  */
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    font-family: Nunito, Light;
    /*   */
    padding-bottom: 6px;
  }
}

/* Devices iPad portrait (768px x 1024px) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
  .CalcWidgetHeight {
    height: 52vh;
  }
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 339px;
    overflow-y: auto;
    padding-top: 6px;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .OverNotifiction {
    height: 230px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 12px;
    height: 100%;
    padding: 2px 0px;
  }
  .blockheight {
    line-height: 12px;
    padding: 12px 0px 8px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 30px 0px 26px 0px;
  }

  .font-normal {
    font-size: 13px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 26px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 16px;
    line-height: 18px;
    margin-bottom: 16px;
  }
  .font-size-18 {
    font-size: 13px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 13px !important;
    line-height: 24px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 11px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }
}

/* Devices iPad landscape (768px x 1024px) */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
  .btn-flex {
    display: flex;
  }
  .swp-widget .mt-2 {
    margin-top: 0.1rem !important;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 339px;
    overflow-y: auto;
    padding-top: 6px;
  }
  h1 .header-title {
    line-height: 40px !important;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }
  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }
  .OverNotifiction {
    height: 240px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 11px;
    height: 100%;
    padding: 0px 0px;
  }
  .blockheight {
    line-height: 12px;
    padding: 17px 0px 17px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 30px 0px 44px 0px;
  }
  .font-normal {
    font-size: 10px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }
  .font-size-37 {
    font-size: 20px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 14px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 11px !important;
    line-height: 20px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 10px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }
  .barPadding {
    font-size: 8px !important;
    display: contents;
    line-height: 1.5px;
    padding-top: 0 !important;
  }
  .overview-page {
    max-width: 252px !important;
  }
  .font-size-lg {
    font-size: 15px !important;
  }
  .overview-page .bordergreenColor .col-md-8,
  .overview-page .border-bottom-overview .col-md-8 {
    padding-left: 5px;
    padding-right: 0px;
  }
  .overview-page .font-icon se-icon,
  .detailscheckBlock se-icon {
    font-size: 30px !important;
  }
  .overview-page .font-icon .text-right .pointer {
    font-size: 12px !important;
  }
  .pl-3 {
    padding-right: 0px !important;
  }
  .LineheightOver {
    padding-left: 1.3rem !important;
  }
  .DayPickerInput input {
    width: 76px !important;
  }
  .DayPickerInput::after {
    top: -1px !important;
    right: -12px !important;
  }
  .mt-30px {
    margin-top: 40px;
  }
  .full-width {
    padding: 0 !important;
    max-width: 200px !important;
    float: left;
  }
  .font-size-14 {
    font-size: 10px !important;
  }
  .full-width se-icon {
    font-size: 20px;
  }
  .full-width img {
    width: 15px;
    height: 15px;
  }
  .realTimeEnergyTable {
    padding: 4px 2px;
    font-size: 9px;
  }
  .emailOption span {
    padding-left: 0px !important;
    display: inline !important;
    top: -3px;
    position: relative;
  }
  .phoneOption span {
    padding-left: 0px !important;
  }
  .tooltip-info se-icon {
    display: none;
  }
  .tooltip-info li {
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px)  and (max-width: 991) {


.pdf-download{position: absolute; right: 0px; top: 0px; z-index: 1; height: 80px;}

.nav > a{padding: 0 12px !important; font-size: 14px !important; line-height: 42px !important;}

.OverNotifiction{height: 230px ;}
.progress {margin: 2px 0px 3px 0px;}
.capacityblockheight{line-height: 12px; height: 100%; padding: 2px 0px;}
.blockheight {line-height: 12px; padding: 12px 0px 8px 0px;}
.blocksheight{padding: 30px 0px 26px 0px;}

.font-normal {font-size: 13px !important; font-weight: 500; font-family: Nunito,Light; }

.font-size-37 {font-size: 26px; color:  #505257;}
.font-size-22{font-size: 16px; line-height: 18px; margin-bottom: 16px;}
.font-size-18 {font-size: 13px; font-weight: 500;} 
.font-size-16 {font-size: 13px !important; line-height: 24px; font-weight: 600; font-family: Nunito,Light; }
.font-size-12 {font-size: 11px;  font-weight: 500;}
.partner-title {font-size: 13px; line-height: 14px; font-weight: 600; font-family: Nunito,Light;  padding-bottom: 6px;}

} */

/* Large devices (laptops/desktops, 992px and up)
@media only screen and (min-width: 992px) and (max-width: 1024px) {
h1 .header-title {line-height: 40px !important;}

.pdf-download{position: absolute; right: 0px; top: 0px; z-index: 1; height: 80px;}

.nav > a{padding: 0 12px !important; font-size: 14px !important; line-height: 42px !important;}

.OverNotifiction{height: 240px ;}
.progress {margin: 2px 0px 3px 0px;}
.capacityblockheight{line-height: 11px; height: 100%; padding: 0px 0px;}
.blockheight {line-height: 12px; padding: 12px 0px 21px 0px;}
.blocksheight{padding: 30px 0px 46px 0px;}

.font-normal {font-size: 13px !important; font-weight: 500; font-family: Nunito,Light; }

.font-size-37 {font-size: 28px; color:  #505257;}
.font-size-22{font-size: 18px; line-height: 18px; margin-bottom: 12px;}
.font-size-18 {font-size: 14px; font-weight: 500;} 
.font-size-16 {font-size: 13px !important; line-height: 24px; font-weight: 600; font-family: Nunito,Light; }
.font-size-12 {font-size: 12px;  font-weight: 500;}
.partner-title {font-size: 13px; line-height: 14px; font-weight: 600; font-family: Nunito,Light;  padding-bottom: 6px;}

} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1025px) and (max-width: 1200px) {
  .CalcWidgetHeight {
    height: 54vh;
  }
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 339px;
    overflow-y: auto;
    padding-top: 8px;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }

  .OverNotifiction {
    height: 240px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 12px;
    height: 100%;
    padding: 2px 0px;
  }
  .blockheight {
    line-height: 12px;
    padding: 12px 0px 21px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 30px 0px 45px 0px;
  }

  .font-normal {
    font-size: 13px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 30px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 18px;
    line-height: 18px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 15px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 13px !important;
    line-height: 24px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 12px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 14px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }
}

@media only screen and (min-width: 1201px) and (max-width: 1280px) {
  .widgettext-left {
    padding-right: 0 !important;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  .icon-large {
    line-height: 52px;
    width: 42px;
    height: 40px;
  }
  .swp-widget .mt-2 {
    margin-top: 0.1rem !important;
  }
  .summery_units {
    margin-top: -2vh;
  }
  /* .fixed-line-height{
    line-height: 0.6;
  } */
  .ct-padding-medium {
    padding: 0;
  }
  .pl-3 {
    padding-left: 0.3rem !important;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .cloudIcon {
    font-size: 30px;
    position: fixed;
    margin-top: -15px;
  }
  .cu_high_low {
    font-size: 16px;
    font-weight: 500;
    padding-top: 0.5rem !important;
  }
  .marginTop {
    margin-top: -10px;
  }
  .full-width {
    display: flex;
  }
  .realtime-widget:first-child,
  :not(.realtime-widget) + .realtime-widget {
    margin-top: 10vh;
  }
  .realTimeEnergyTable {
    font-size: 11px;
    padding: 4px 4px;
  }
  .azureMapControlsStyle {
    width: 23.5vw;
  }
  .gdoverview {
    margin-bottom: -55px;
    margin-top: -25px;
  }
  .LineheightOver {
    line-height: 10px;
  }
  #CustomerMap .Map {
    height: 380px !important;
    width: 460px !important;
    position: absolute;
  }
  #CustomerMap .CreateLanMap {
    height: 380px !important;
    width: 525px !important;
    position: absolute;
  }
  .Scroll {
    height: 339px;
    overflow-y: auto;
    padding-top: 6px;
  }

  .px-0-xl {
    padding: 0px !important;
  }
  .pdf-download {
    position: absolute;
    right: 30px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }

  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }
  .progress-group-header {
    padding-top: 2.5px;
  }
  .OverNotifiction {
    height: 240px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 10.5px;
    height: 100%;
    padding: 0px;
    overflow: hidden;
  }
  .blockheight {
    line-height: 14px;
    padding: 20px 0 0 0;
    overflow: hidden;
  }
  .agriblockheight {
    padding-top: 5px;
  }
  .envblockheight {
    padding-top: 3px;
  }
  .cublockheight {
    padding-top: 6px;
  }
  .ecblockheight {
    padding-top: 15px;
  }
  .incomeblockheight {
    padding-top: 1px;
  }
  .blocksheight {
    padding: 41px 0px 36px 0px;
  }

  .font-normal {
    font-size: 13px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 27px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 13px;
    font-weight: 500;
  }
  .font-size-14 {
    font-size: 12px !important;
  }
  .next-line {
    display: block;
  }
  .font-size-16 {
    font-size: 12px !important;
    line-height: 30px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 12px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }

  .createUserWidgetHeight {
    height: 485px !important;
  }
  .divHeight {
    height: 18px;
    padding-left: 15px;
    padding-top: 0px;
  }
  .font-size-lg {
    font-size: 22px !important;
    color: #505257;
  }
  .barPadding {
    font-size: 9px !important;
  }
  .widgetDetails {
    height: 590px;
  }

  .ChooseDivHeight {
    height: 290px;
  }
  .dash1 {
    border: 0.25px solid #e47f00;
    width: 15px;
    height: 0px;
  }
  .dash2 {
    border: 0.25px solid #3dcd58;
    width: 14px;
    height: 0px;
  }
  .dash {
    border: 0.25px solid #0087cd;
    width: 20px;
    height: 0px;
  }
  .dash3 {
    border: 0.25px solid #ffb754;
    width: 20px;
    height: 0px;
  }
  .dash4 {
    border: 0.25px solid #626469;
    width: 20px;
    height: 0px;
  }
  .dash5 {
    border: 0.25px solid #ffd100;
    width: 20px;
    height: 0px;
  }

  .chartData {
    width: 610px;
    height: 310px;
  }
  .incomeDoughnutchartData {
    width: 570px;
    height: 160px;
  }

  .customer-info p:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    width: 450px;
  }
  .customer-info p:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    width: 450px;
  }
  .emailOption span {
    width: 210px !important;
  }
  .installation-detail se-table-item {
    border: 1px solid lightgray;
    padding: 0px;
    height: 32px;
    overflow: hidden;
    position: relative;
    top: -6px;
  }
  .deviceInfo-caption {
    background-color: rgb(159, 160, 164);
    color: white;
    flex: 1 1 100%;
    caption-side: top !important;
  }

  .pumpTable se-table-item {
    border: 1px solid lightgray;
    padding: 0px;
    height: 32px;
    overflow: hidden;
    position: relative;
    top: -6px;
  }
  .pumpTable se-table-item input {
    font-size: 13px;
  }
  .pumpTable se-table-item select {
    font-size: 13px;
  }
  .pumpTable se-table-item-header {
    font-size: 13px;
  }
  .pumpTable se-table-item input.se-form-control.se-form-success {
    height: 30px !important;
    top: 0px !important;
  }
  .pumpTable se-table-item select.se-form-control.se-form-success {
    height: 30px !important;
    top: 0px !important;
  }
  .pumpTable se-table-item input.se-form-control.se-form-error {
    height: 30px !important;
    top: 0px !important;
  }
  .pumpTable se-table-item select.se-form-control.se-form-error {
    height: 30px !important;
    top: 0px !important;
  }
  .userProfileHeader1Height {
    height: 63px;
  }
  .userProfileHeader2Height {
    height: 46px;
  }
  .tooltip-info li {
    padding: 0px;
    font-size: 9px;
    font-weight: 100;
  }
  .tooltip-info .tooltip-container {
    height: 50px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1281px) and (max-width: 1366px) {
  .swp-widget .mt-2 {
    margin-top: 0.1rem !important;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  /* .fixed-line-height{
    line-height: 0.6;
  } */
  .ct-padding-medium {
    padding: 0;
  }
  .blockmycustomerDiv {
    height: 59px;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .cloudIcon {
    font-size: 30px;
    position: fixed;
    margin-top: -15px;
  }
  .cu_high_low {
    font-size: 18px;
    font-weight: 500;
    padding-top: 0.5rem !important;
  }
  .marginTop {
    margin-top: -10px;
  }
  .full-width {
    display: flex;
  }
  .realtime-widget:first-child,
  :not(.realtime-widget) + .realtime-widget {
    margin-top: 10vh;
  }
  .realTimeEnergyTable {
    font-size: 11px;
    padding: 4px 4px;
  }
  .azureMapControlsStyle {
    width: 23.5vw;
  }
  .gdoverview {
    margin-bottom: -55px;
    margin-top: -25px;
  }
  .LineheightOver {
    line-height: 10px;
  }
  #CustomerMap .Map {
    height: 380px !important;
    width: 460px !important;
    position: absolute;
  }
  #CustomerMap .CreateLanMap {
    height: 380px !important;
    width: 525px !important;
    position: absolute;
  }
  .Scroll {
    height: 339px;
    overflow-y: auto;
    padding-top: 6px;
  }

  .px-0-xl {
    padding: 0px !important;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }

  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }
  .progress-group-header {
    padding-top: 2.5px;
  }
  .OverNotifiction {
    height: 240px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 10.5px;
    height: 100%;
    padding: 0px;
    overflow: hidden;
  }
  .blockheight {
    line-height: 14px;
    padding: 0px 0 0 0;
    overflow: hidden;
  }
  .agriblockheight {
    padding-top: 5px;
  }
  .envblockheight {
    padding-top: 3px;
  }
  .cublockheight {
    padding-top: 6px;
  }
  .ecblockheight {
    padding-top: 15px;
  }
  .incomeblockheight {
    padding-top: 1px;
  }
  .blocksheight {
    padding: 41px 0px 36px 0px;
  }

  .font-normal {
    font-size: 13px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 27px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 15px;
    font-weight: 500;
  }
  .font-size-14 {
    font-size: 12px !important;
  }
  .next-line {
    display: block;
  }
  .font-size-16 {
    font-size: 12px !important;
    line-height: 30px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 12px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }

  .createUserWidgetHeight {
    height: 485px !important;
  }
  .divHeight {
    height: 18px;
    padding-left: 15px;
    padding-top: 0px;
  }
  .font-size-lg {
    font-size: 22px !important;
    color: #505257;
  }
  .barPadding {
    font-size: 9px !important;
  }
  .widgetDetails {
    height: 590px;
  }

  .ChooseDivHeight {
    height: 290px;
  }
  .dash1 {
    border: 0.25px solid #e47f00;
    width: 15px;
    height: 0px;
  }
  .dash2 {
    border: 0.25px solid #3dcd58;
    width: 14px;
    height: 0px;
  }
  .dash {
    border: 0.25px solid #0087cd;
    width: 20px;
    height: 0px;
  }
  .dash3 {
    border: 0.25px solid #ffb754;
    width: 20px;
    height: 0px;
  }
  .dash4 {
    border: 0.25px solid #626469;
    width: 20px;
    height: 0px;
  }
  .dash5 {
    border: 0.25px solid #ffd100;
    width: 20px;
    height: 0px;
  }

  .chartData {
    width: 610px;
    height: 310px;
  }
  .incomeDoughnutchartData {
    width: 570px;
    height: 160px;
  }

  .customer-info p:nth-child(2) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    width: 450px;
  }
  .customer-info p:first-child {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0px;
    width: 450px;
  }
  .emailOption span {
    width: 210px !important;
  }
  .installation-detail se-table-item {
    border: 1px solid lightgray;
    padding: 0px;
    height: 32px;
    overflow: hidden;
    position: relative;
    top: -6px;
  }
  .deviceInfo-caption {
    background-color: rgb(159, 160, 164);
    color: white;
    flex: 1 1 100%;
    caption-side: top !important;
  }

  .pumpTable se-table-item {
    border: 1px solid lightgray;
    padding: 0px;
    height: 32px;
    overflow: hidden;
    position: relative;
    top: -6px;
  }
  .pumpTable se-table-item input {
    font-size: 13px;
  }
  .pumpTable se-table-item select {
    font-size: 13px;
  }
  .pumpTable se-table-item-header {
    font-size: 13px;
  }
  .pumpTable se-table-item input.se-form-control.se-form-success {
    height: 30px !important;
    top: 0px !important;
  }
  .pumpTable se-table-item select.se-form-control.se-form-success {
    height: 30px !important;
    top: 0px !important;
  }
  .pumpTable se-table-item input.se-form-control.se-form-error {
    height: 30px !important;
    top: 0px !important;
  }
  .pumpTable se-table-item select.se-form-control.se-form-error {
    height: 30px !important;
    top: 0px !important;
  }
  .userProfileHeader1Height {
    height: 63px;
  }
  .userProfileHeader2Height {
    height: 46px;
  }
  .tooltip-info li {
    padding: 0px;
    font-size: 9px;
    font-weight: 100;
  }
  .tooltip-info .tooltip-container {
    height: 50px;
  }
}
@media only screen and (width: 1360px) {
  .blockheight {
    padding: 8px 0 0px !important;
    overflow: hidden;
  }
}
@-moz-document url-prefix() {
  @media only screen and (min-width: 1201px) and (max-width: 1366px) {
    .LineheightOver {
      line-height: 10px;
    }
    #CustomerMap .Map {
      height: 380px !important;
      width: 460px !important;
      position: absolute;
    }
    #CustomerMap .CreateLanMap {
      height: 380px !important;
      width: 525px !important;
      position: absolute;
    }
    .Scroll {
      height: 339px;
      overflow-y: auto;
      padding-top: 6px;
    }

    .px-0-xl {
      padding: 0px !important;
    }
    .pdf-download {
      position: absolute;
      right: 20px;
      top: 0px;
      z-index: 1;
      height: 80px;
    }

    .nav > a {
      padding: 0 12px !important;
      font-size: 14px !important;
      line-height: 42px !important;
    }

    .OverNotifiction {
      height: 226px;
    }
    .progress {
      margin: 2px 0px 3px 0px;
    }
    .capacityblockheight {
      line-height: 14px;
      height: 100%;
      padding: 2px 0px;
    }
    .blockheight {
      line-height: 14px;
      padding: 11px 0px 16px 0px;
      overflow: hidden;
    }
    .blocksheight {
      padding: 41px 0px 36px 0px;
    }

    .font-normal {
      font-size: 13px !important;
      font-weight: 500;
      font-family: Nunito, Light;
    }

    .font-size-37 {
      font-size: 32px;
      color: #505257;
    }
    .font-size-22 {
      font-size: 20px;
      line-height: 20px;
      margin-bottom: 12px;
    }
    .font-size-18 {
      font-size: 15px;
      font-weight: 500;
    }
    .font-size-16 {
      font-size: 13px !important;
      line-height: 30px;
      font-weight: 600;
      font-family: Nunito, Light;
    }
    .font-size-RealWidget-16 {
      font-size: 13px !important;
      font-family: Nunito, Light;
    }
    .font-size-12 {
      font-size: 12px;
      font-weight: 500;
    }
    .partner-title {
      font-size: 13px;
      line-height: 20px;
      font-weight: 600;
      font-family: Nunito, Light;
      padding-bottom: 6px;
    }

    .createUserWidgetHeight {
      height: 485px !important;
    }
    .divHeight {
      height: 18px;
      padding-left: 15px;
      padding-top: 0px;
    }
    .font-size-lg {
      font-size: 22px;
      color: #505257;
    }
    .widgetDetails {
      height: 590px;
    }

    .ChooseDivHeight {
      height: 290px;
    }
    .dash1 {
      border: 0.25px solid #e47f00;
      width: 15px;
      height: 0px;
    }
    .dash2 {
      border: 0.25px solid #3dcd58;
      width: 14px;
      height: 0px;
    }
    .dash {
      border: 0.25px solid #0087cd;
      width: 20px;
      height: 0px;
    }
    .dash3 {
      border: 0.25px solid #ffb754;
      width: 20px;
      height: 0px;
    }
    .dash4 {
      border: 0.25px solid #626469;
      width: 20px;
      height: 0px;
    }
    .dash5 {
      border: 0.25px solid #ffd100;
      width: 20px;
      height: 0px;
    }

    .chartData {
      width: 610px;
      height: 310px;
    }
    .incomeDoughnutchartData {
      width: 570px;
      height: 160px;
    }

    .customer-info p:nth-child(2) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      width: 450px;
    }
    .customer-info p:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 0px;
      width: 450px;
    }
    .emailOption span {
      width: 210px !important;
    }
    .pumpTable se-table-item {
      border: 1px solid lightgray;
      padding: 0px;
      height: 32px;
      overflow: hidden;
      position: relative;
      top: -6px;
    }
    .pumpTable se-table-item input {
      font-size: 13px;
    }
    .pumpTable se-table-item select {
      font-size: 13px;
    }
    .pumpTable se-table-item-header {
      font-size: 13px;
    }

    .pumpTable se-table-item {
      border: none;
      display: block;
      position: relative;
      top: -0px !important;
    }
    .pumpTable se-table-item input.se-form-control.se-form-success {
      height: 28px !important;
      top: 2px !important;
    }
    .pumpTable se-table-item select.se-form-control.se-form-success {
      height: 28px !important;
      top: 2px !important;
    }
    .pumpTable se-table-item input.se-form-control.se-form-error {
      height: 28px !important;
      top: 2px !important;
    }
    .pumpTable se-table-item select.se-form-control.se-form-error {
      height: 28px !important;
      top: 2px !important;
    }
    .userProfileHeader1Height {
      height: 63px;
    }
    .userProfileHeader2Height {
      height: 46px;
    }
    .tooltip-info li {
      padding: 0px;
      font-size: 9px;
      font-weight: 100;
    }
    .tooltip-info .tooltip-container {
      height: 50px;
    }
  }
}

/* Extra large devices (large laptops and desktops, 1367px and up) */
@media only screen and (min-width: 1367px) and (max-width: 1400px) {
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 342px;
    overflow-y: auto;
    padding-top: 8px;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .px-0-xl {
    padding: 0px !important;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }
  .cloudIcon {
    font-size: 40px;
    position: fixed;
    margin-top: -15px;
  }
  .cu_high_low {
    font-size: 20px;
    font-weight: 500;
    padding-top: 0.5rem !important;
  }
  .nav > a {
    padding: 0 12px !important;
    font-size: 14px !important;
    line-height: 42px !important;
  }
  .gdoverview {
    margin-bottom: -40px;
    margin-top: -20px;
  }
  .OverNotifiction {
    height: 226px;
  }
  .progress {
    margin: 2px 0px 3px 0px;
  }
  .capacityblockheight {
    line-height: 14px;
    height: 100%;
    padding: 2px 0px;
  }
  .blockheight {
    line-height: 14px;
    padding: 15px 0px 22px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 41px 0px 44px 0px;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  .font-normal {
    font-size: 13px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 32px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 15px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 13px !important;
    line-height: 30px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 13px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 12px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 13px;
    line-height: 20px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }

  .createUserWidgetHeight {
    height: 485px !important;
  }
  .divHeight {
    height: 18px;
    padding-left: 15px;
    padding-top: 0px;
  }
  .font-size-lg {
    font-size: 22px;
    color: #505257;
  }
  .widgetDetails {
    height: 590px;
  }

  .ChooseDivHeight {
    height: 290px;
  }
  .dash1 {
    border: 0.25px solid #e47f00;
    width: 15px;
    height: 0px;
  }
  .dash2 {
    border: 0.25px solid #3dcd58;
    width: 14px;
    height: 0px;
  }
  .dash {
    border: 0.25px solid #0087cd;
    width: 20px;
    height: 0px;
  }
  .dash3 {
    border: 0.25px solid #ffb754;
    width: 20px;
    height: 0px;
  }
  .dash4 {
    border: 0.25px solid #626469;
    width: 20px;
    height: 0px;
  }
  .dash5 {
    border: 0.25px solid #ffd100;
    width: 20px;
    height: 0px;
  }

  .chartData {
    width: 610px;
    height: 310px;
  }
  .incomeDoughnutchartData {
    width: 570px;
    height: 160px;
  }
  #CustomerMap .Map {
    height: 380px !important;
    width: 525px !important;
    position: absolute;
  }
  #CustomerMap .CreateLanMap {
    height: 380px !important;
    width: 525px !important;
    position: absolute;
  }
}

@media only screen and (min-width: 1401px) and (max-width: 1440px) {
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 470px;
    overflow-y: auto;
    padding-top: 8px;
  }
  .px-0-xl {
    padding: 0px !important;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }
  .nav > a {
    padding: 0 16px !important;
    font-size: 15px !important;
    line-height: 42px !important;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .OverNotifiction {
    height: 290px;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .progress {
    margin: 4px 0px 6px 0px;
  }
  .cloudIcon {
    font-size: 40px;
    position: fixed;
    margin-top: -15px;
  }
  .cu_high_low {
    font-size: 20px;
    font-weight: 500;
    padding-top: 0.5rem !important;
  }
  .capacityblockheight {
    line-height: 16px;
    height: 100%;
    padding: 4px 0px;
  }
  .blockheight {
    line-height: 14px;
    padding: 24px 0px 30px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 62px 0px 56px 0px;
  }
  .gdoverview {
    margin-bottom: -70px;
    margin-top: -20px;
  }
  .font-normal {
    font-size: 15px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  .font-size-37 {
    font-size: 27px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 21px;
    line-height: 21px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 16px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 15px !important;
    line-height: 34px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 16px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 13px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 15px;
    line-height: 24px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }
  .full-width {
    display: flex;
  }
}

/* @media only screen and (min-width: 1440px) and (max-width: 1600px) {

.OverNotifiction{height: 290px ;}
.progress {margin: 4px 0px 6px 0px;}

.capacityblockheight{line-height: 14px; height: 100%; padding: 2px 0px;}
.blockheight {line-height: 14px; padding: 30px 0px 34px 0px;}
.blocksheight{padding: 73px 0px 48px 0px;}

.font-normal {font-size: 15px !important; font-weight: 500; font-family: Nunito,Light; }
.font-size-16 {font-size: 15px !important; line-height: 30px; font-weight: 600; font-family: Nunito,Light; }
.font-size-12 {font-size: 13px;  font-weight: 500;}
.partner-title {font-size: 15px; line-height: 20px; font-weight: 600; font-family: Nunito,Light;  padding-bottom: 6px;}

} */

@media only screen and (min-width: 1441px) and (max-width: 1600px) {
  .summery_units {
    margin-top: -2vh;
  }
  .realtime-widget:first-child,
  :not(.realtime-widget) + .realtime-widget {
    margin-top: 10vh;
  }
  .LineheightOver {
    line-height: 10px;
  }
  .Scroll {
    height: 435px;
    overflow-y: auto;
    padding-top: 8px;
  }
  .downarrowposition {
    margin: 5px 0 0 -5px;
  }
  .cloudIcon {
    font-size: 40px;
    position: fixed;
    margin-top: -15px;
  }
  .cu_high_low {
    font-size: 20px;
    font-weight: 500;
    padding-top: 0.5rem !important;
  }
  .px-0-xl {
    padding: 0px !important;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  .nav > a {
    padding: 0 16px !important;
    font-size: 16px !important;
    line-height: 42px !important;
  }

  .OverNotifiction {
    height: 285px;
  }
  .progress {
    margin: 4px 0px 6px 0px;
  }

  .capacityblockheight {
    line-height: 14px;
    height: 100%;
    padding: 2px 0px;
  }
  .blockheight {
    line-height: 15px;
    padding: 25px 0px 0px 0px;
    overflow: hidden;
  }
  .agriblockheight {
    padding-top: 5px;
  }
  .envblockheight {
    padding-top: 10px;
  }
  .cublockheight {
    padding-top: 9px;
  }
  .ecblockheight {
    padding-top: 20px;
  }
  .incomeblockheight {
    padding-top: 6px;
  }
  .blocksheight {
    padding: 59px 0px 48px 0px;
  }
  .gdoverview {
    margin-bottom: -85px;
    margin-top: -30px;
  }
  .font-normal {
    font-size: 15px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 36px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 17px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 15px !important;
    line-height: 30px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 16px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 13px;
    font-weight: 500;
  }
  .partner-title {
    font-size: 15px;
    line-height: 20px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }
}
@-moz-document url-prefix() {
  @media only screen and (min-width: 1441px) and (max-width: 1600px) {
    .blockheight {
      padding: 15px 0px 34px 0px;
      overflow: hidden;
    }
  }
}

/* Engineering Laptops / Desktops*/
@media only screen and (min-width: 1601px) and (max-width: 1920px) {
  .LineheightOver {
    line-height: 10px;
  }
  .summery_units {
    margin-top: -2vh;
  }
  .Scroll {
    height: 647px;
    overflow-y: auto;
    padding-top: 8px;
  }
  .createUserWidgetHeight {
    height: 560px !important;
  }
  .pumpstatus_icon {
    height: 48px;
    padding: 12px 10px 8px;
  }
  #CustomerMap .Map {
    height: 380px !important;
    width: 525px !important;
    position: absolute;
  }
  #CustomerMap .CreateLanMap {
    height: 380px !important;
    width: 525px !important;
    position: absolute;
  }
  .px-0-xl {
    padding: 0px !important;
  }
  .pdf-download {
    position: absolute;
    right: 20px;
    top: 0px;
    z-index: 1;
    height: 80px;
  }

  .nav > a {
    padding: 0 16px !important;
    font-size: 16px !important;
    line-height: 42px !important;
  }

  .OverNotifiction {
    height: 400px;
  }
  .progress {
    margin: 4px 0px 6px 0px;
  }

  .capacityblockheight {
    line-height: 20px;
    height: 100%;
    padding: 2px 0px;
  }
  .blockheight {
    line-height: 14px;
    padding: 36px 0px 60px 0px;
    overflow: hidden;
  }
  .blocksheight {
    padding: 80px 0px 94px 0px;
  }

  .font-normal {
    font-size: 16px !important;
    font-weight: 500;
    font-family: Nunito, Light;
  }

  .font-size-37 {
    font-size: 36px;
    color: #505257;
  }
  .font-size-22 {
    font-size: 22px;
    line-height: 22px;
    margin-bottom: 12px;
  }
  .font-size-18 {
    font-size: 17px;
    font-weight: 500;
  }
  .font-size-16 {
    font-size: 16px !important;
    line-height: 40px;
    font-weight: 600;
    font-family: Nunito, Light;
  }
  .font-size-RealWidget-16 {
    font-size: 16px !important;
    font-family: Nunito, Light;
  }
  .font-size-12 {
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .font-size-10 {
    font-size: 14px !important;
    font-weight: 500;
  }

  .partner-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    font-family: Nunito, Light;
    padding-bottom: 6px;
  }

  .barPadding {
    padding-top: 8px;
    font-size: 14px !important;
    font-family: Nunito;
  }
  .progressBar {
    border-radius: 7px !important;
    height: 0.5rem !important;
  }

  .bordergreenColor {
    border-top: 5px solid #3dcd58 !important;
  }
  .borderredColor {
    border-top: 5px solid #dc0a0a !important;
  }
  .borderColor {
    border-top: 5px solid #626469 !important;
  }
  .userProfileHeaderHeight {
    height: 46px !important;
  }
}

/*-------login screen--------*/
.main-login-screen {
  background: #f7f7f7;
  position: absolute;
  width: 100%;
  height: 100%;
}
se-authentication {
  height: 620px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
}
.main-login-screen se-form-field input {
  margin-top: -5px;
}
.main-login-screen se-button {
  margin-top: -5px;
  border: none;
}

/*------end login screen----------*/
/*------mycustomer----------------*/
.customer-info p {
  font-size: 14px !important;
  font-family: Nunito, Regular !important;
  padding: 0px;
  margin: 0px;
}
.customer-info p:first-child {
  margin-top: 14px;
}
.customer-info p:nth-child(2) {
  margin-bottom: 0;
  line-height: 1;
}
.customer-info strong {
  font-size: 16px !important;
  font-family: Nunito, SemiBold !important;
  line-height: 0.1;
  cursor: pointer;
}
.imageContainer {
  flex: 0 !important;
  padding: 0px !important;
}

.pumpTable se-table-item select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
  background: #f7f7f7;
  text-transform: capitalize;
  color: black;
}
.pumpTable se-table-item select:enabled {
  background: white;
  color: black;
}

.pumpTable se-table-item select::-ms-expand {
  display: none;
  position: relative;
  top: 1px;
}

@-moz-document url-prefix() {
  .pumpTable se-table-item select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: "";
    background: #e3e3e3;
    text-transform: capitalize;
    color: #6d6d6d;
  }
  .myCustomerPumpTable select.se-form-control.se-form-success {
    top: -8.5px;
  }
  .pumpTable se-table-group {
    border: none;
    display: block;
    position: relative;
    top: -5.5px !important;
  }
}
.pumpTable se-table-item input.se-form-control.se-form-success {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3dcd58;
  box-shadow: 0 0 3px #3dcd58;
  border-radius: 0px;
  opacity: 1;
  height: 28px;
  padding: 5px;
  width: 100%;
  position: absolute;
  top: 2px;
  right: 0px;
  left: 0px;
}
.pumpTable se-table-item select.se-form-control.se-form-success {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3dcd58;
  box-shadow: 0 0 3px #3dcd58;
  border-radius: 0px;
  opacity: 1;
  height: 28px;
  padding: 0px 5px;
  width: 100%;
  position: absolute;
  top: 2px;
  right: 0px;
  left: 0px;
}
.pumpTable se-table-item select.se-form-control.se-form-error {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dc0a0a;
  box-shadow: 0 0 3px #dc0a0a;
  border-radius: 0px;
  opacity: 1;
  height: 28px;
  padding: 0px 5px;
  width: 100%;
  position: absolute;
  top: 2px;
  right: 0px;
  left: 0px;
}
.pumpTable se-table-item input.se-form-control.se-form-error {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dc0a0a;
  box-shadow: 0 0 3px #dc0a0a;
  border-radius: 0px;
  opacity: 1;
  height: 28px;
  padding: 5px;
  width: 100%;
  position: absolute;
  top: 2px;
  right: 0px;
  left: 0px;
}
.pumpTable se-table-item input.se-form-control {
  padding: 5px;
}
.emailOption span {
  padding-left: 30px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  width: 220px;
  display: inline-block;
}
.phoneOption span {
  padding-left: 30px;
  top: -3px;
  position: relative;
}

.pumpTable se-table-item input {
  background: white;
  padding: 3px 15px;
  text-align: left;
  position: absolute;
  top: 0px;
  left: 0px;
}
.pumpTable se-table-item select {
  background: white;
  padding: 3px 15px;
  position: absolute;
  top: 0px;
  left: 0px;
}
.pumpTable se-table-item-header {
  font-weight: 600;
  font-size: 13px;
  border: 1px solid lightgray;
  border-bottom: none;
  height: 32px;
}
.pumpTable se-table-item-header strong {
  position: absolute;
  top: 3px;
}
.pumpTable se-table-item label {
  position: absolute;
  top: 2px;
}
.pumpTable se-table-item-header span {
  position: absolute;
  /* top: -7px; */
}
.pumpTable se-table-item {
  border: 1px solid lightgray;
  padding: 0px;
  height: 32px;
  overflow: hidden;

  top: -1px;
}
.pumpTable se-checkbox {
  position: absolute;
  right: 0px;
  top: 0px;
}
.pumpTable se-table-group {
  border: none;
  display: block;
  position: relative;
}

/*----------------my customer end -------------*/
.se-app-body,
body {
  background: #f7f7f7 !important;
  font-family: "Nunito" !important;
}

.table-component table tr:nth-child(odd) {
  background: #f7f7f7;
}
.table-component .pagination {
  float: right;
  padding-right: 8px;
}
.notifications-component .pagination {
  float: right;
  padding-right: 8px;
}
/* .valign-super{} */

.active-icon {
  background: #505559;
  height: 24px;
  padding: 4px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-left: -5px;
}
.icon {
  border: 1px solid #505559;
  height: 22px;
  padding: 4px;
  line-height: 22px;
  text-align: center;
  margin-left: 5px;
}

.align_Label {
  float: left;
  font-size: 15px;
  background-color: #ededed;
  padding: 10px;
}

.accordion {
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border: none;
}

.accordion:hover {
  background-color: #ededed;
}

.accordion:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.accordion1 {
  background-color: #ffffff;
  color: #333333;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  border-bottom: 1px solid #ccc !important;
  border-top: 1px solid #ccc !important;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  border: none;
}

.accordion1:hover {
  background-color: #ededed;
}

.accordion1:after {
  content: "\002B";
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  /* content: "\2212"; */
}

.panel {
  padding: 0 18px;
  background-color: white;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}
.buttonSize {
  width: 100%;
}
.align_span {
  padding: 5px;
  border-top: 1px solid lightgrey;
  padding-right: 5px;
}

.profile_fontsize {
  font-size: 14px;
  color: #333333;
  /* height: 100%; */
}
.LineHeight {
  line-height: 17px;
}
.profileImage {
  font-size: 120px !important;
  height: 120px;
  color: #cbcbcb;
}
.userProfileImage {
  font-size: 101px !important;
  height: 100px;
  color: #cbcbcb;
}

.paddingZero {
  padding: 0;
}

.buttondisplay {
  display: block;
}

.buttonhide {
  display: none;
}

.se-deploy-div {
  font-size: 12px;
  font-family: sans-serif;
  vertical-align: middle;
  padding-top: 4px;
  text-align: center;
}

.tab-content-deploy {
  background-color: #ffffff;
  padding: 0px 15px;
  height: 40px;
  vertical-align: middle;
  border: solid 1px #cbcbcb;
}

.grey-background {
  background-color: #cbcbcb;
}
.grey-backgroundCreate {
  background-color: #f7f7f7;
}
.grey-backgroundOverview {
  background-color: #e6e6e6;
  /* height: 70px; */
}

.circle {
  width: 130px;
  height: 130px;
  line-height: 130px;
  border-radius: 50%;
  /* the magic */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 700;
  background-color: #f7f7f7;
}
.mediamcircle {
  width: 60px;
  height: 60px;
  line-height: 50px;
  border-radius: 50%;
  /* the magic */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  font-size: 10px;
  font-weight: 700;
  border: 2px solid #00000029;
}
.smallcircle {
  width: 55px;
  height: 55px;
  line-height: 55px;
  border-radius: 50%;
  /* the magic */
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  text-align: center;
  font-size: 16px;
  /* text-transform: uppercase; */
  font-weight: 700;
  border: 1.5px solid #00000029;
}

.font-weight-400 {
  font-weight: 400;
}

.font-size-24 {
  font-size: 24px;
}

.font-weight-500 {
  font-weight: 500;
}

se-mandatory {
  color: #ff3333;
}
.se-error-text {
  color: #ff3333;
  font-size: 12px;
}
.profile {
  font-size: 110px !important;
  height: 120px;
  color: #cbcbcb;
  left: -70px !important;
  padding-right: 40px !important;
  padding-left: 60px !important;
}
.pro {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.widgetHederFount {
  font-size: 12px;
  text-align-last: left;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-20 {
  font-size: 20px;
  font-weight: 500;
}

.custList {
  cursor: pointer;
  font-size: 16px;
  padding-left: 18px;
  /* font-family: Arial;  */
}
.border-bottom-thin {
  border-bottom: 1px solid #cbcbcb;
  padding-top: 10px;
  padding-bottom: 10px;
}
.doubleBlock {
  margin-top: 25px;
  margin-bottom: 25px;
}
.hideBlock {
  display: none;
}
.myProfile {
  color: #cbcbcb;
  overflow: hidden;
}
.ProfileIcon {
  display: flex;
  flex-wrap: wrap;
}

.createNewUserIcon {
  font-size: 100px !important;
  color: #cbcbcb;
  margin-top: 20%;
}

.pading {
  padding-left: 15px;
  /* padding-right: 15px; */
}
input[type="search"] {
  font-family: "Nunito", "Segoe UI", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* Overridden by font: -webkit-small-control; */
  line-height: 0;
  background-color: #f7f7f7;
  cursor: pointer;
  background-image: url("../images/search.png");
  background-repeat: no-repeat;
  padding: 12px 5px;
  border: 1px solid #f5f5f5;
  font-size: 15px;
  height: 48px;
  padding-left: 12px;
  padding-right: 30px;
  box-shadow: 0px 3px 6px #00000029;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: right;
}
input[type="search1"] {
  font-family: "Nunito", "Segoe UI", "Helvetica Neue", "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* Overridden by font: -webkit-small-control; */
  line-height: 0;
  cursor: pointer;
  background-image: url("../images/search.png");
  background-repeat: no-repeat;
  padding: 6px 12px;
  border: 1px solid #f5f5f5;
  font-size: 15px;
  height: 32px;
  box-shadow: 0px 3px 6px #00000029;
  background-repeat: no-repeat;
  background-size: 25px 25px;
  background-position: right;
}
input[type="search"],
input[type="text"] {
  outline: none !important;
}
input[type="search1"] {
  outline: none !important;
  width: 224px;
}
/* Utilized details */
.headerColor {
  background-color: var(--se-primary, #3dcd58);
  color: var(--se-primary-contrast, #fff);
  font-size: 20px;
}
.dataBoader {
  border: 1px solid lightgrey;
  height: 525px;
}
.divHeight {
  height: 100px;
  text-align: center;
}
.chartData {
  width: 650px;
  height: 350px;
}

.incomeDoughnutchartData {
  width: 675px;
  height: 200px;
}

.text-align {
  text-align: center;
}

.toppad8 {
  padding-top: 8px;
}
.barpad {
  padding-top: 5px;
}
.error {
  margin-left: 5px;
  font-size: 13px;
  color: red;
}
.notification-info {
  margin-left: 5px;
  font-size: 13px;
  color: rgb(255, 209, 0);
}
.impactMetrics {
  text-align: left;
}
.font-weight-600 {
  font-weight: 600;
}
.head {
  width: 100%;
}
.block {
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid #e6dddd;
}
.pumpTab {
  text-align: center;
  padding: 0.5rem;
  vertical-align: top;
  vertical-align: bottom;
  border-top: 0px solid #dee2e6 !important;
  border-bottom: 0px solid #dee2e6 !important;
}

.lpd-10 {
  padding-left: 10px;
}

.text-align {
  text-align: left;
  color: #333333;
  /* padding-left: 2px; */
}
.font-size-lg {
  font-size: 25px;
  color: #505257;
}
.border-bottom-overview {
  border-bottom: 1px solid #00000029;
}

/* .overviewHeight{
    height: 606px;
  } */

.divHeight {
  height: 24px;
  padding-left: 15px;
  padding-top: 2px;
}

.bordergreenColor {
  border-top: 3px solid #3dcd58;
}
.borderredColor {
  border-top: 3px solid #dc0a0a;
}
.borderColor {
  border-top: 3px solid #626469;
}

.installationBorder {
  border: 1px solid #00000029;
}

.rowWidth {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-left: 6px;
}
.paddingBottom {
  padding-bottom: 20px;
}
.paddingBottom30 {
  padding-bottom: 30px;
}

.rows {
  display: flex;
  flex-wrap: wrap;
  margin-right: none;
  margin-left: none;
}
.text-align-header {
  text-align: end;
}
.padding {
  padding-left: 13px;
  /* padding-top: 10px; */
}

.paddingleft15 {
  padding-left: 15px;
}
.bgColorE47F00 {
  background-color: #e47f00;
}
.bgColor219BFD {
  background-color: #219bfd;
}
.bgColorFFD100 {
  background-color: #ffd100;
}
.bgColor626469 {
  background-color: #626469;
}

.barPadding {
  padding-top: 8px;
  font-size: 10px;
  font-family: Nunito;
}
.progressBar {
  border-radius: 7px !important;
  height: 0.5rem !important;
}

.progressBarInDetails {
  width: 13vw;
  border-radius: 7px !important;
  height: 0.7rem !important;
}
.font-size-10 {
  font-size: 10px;
  font-weight: 500;
}
.paddingLeft5 {
  padding-left: 5px !important;
}
.paddingLeft15 {
  padding-left: 10px !important;
}

.paddingLeft25 {
  padding-left: 20px !important;
}

.textColor {
  color: #727272;
}
.NotificationHeaderColor {
  color: #3dcd58;
}
.NotificationHeight {
  height: 75px;
}

.blockDiv {
  display: block;
  /* padding-left: 20px; */
  /* padding-right: 140px; */
}
.blockDetailsDiv {
  display: block;
}
.blockmycustomerDiv {
  display: block;
}
.noneDiv {
  display: none;
}

.agroDeviceTitle {
  color: #3dcd58;
  vertical-align: sub;
}

.paddingleft0 {
  padding-left: 0px !important;
}
.paddingright0 {
  padding-right: 0px !important;
}

/* my customer page */
.padingRight10 {
  padding-right: 10px;
}
.img-Size {
  height: 100%;
  width: 100%;
  height: 395px;
  width: 585px;
}
.padingLeft450 {
  padding-left: 450px;
}

.hydrated nav ol {
  height: 0px !important;
}
.SEtabbar {
  height: 35px;
  background-color: #ffffff !important;
}

table th,
td,
tr {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
}
.tableRowWidth {
  /* min-width: 1080px; */
  width: 100%;
}

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  border: 1px solid #cbcbcb;
  /* background-color: #eee; */
}
/* On mouse-over, add a grey background color */
/* .container:hover input ~ .checkmark {
  background-color: #ccc;
} */
/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #3dcd58;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 5px;
  top: 2px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
.PaddingRight {
  /* padding-right: 250px !important; */
  padding-right: 80px !important;
}

ol {
  height: 0px !important;
}
.seStepper {
  height: 45px !important;
}

.widgetDetails {
  height: 645px;
}
.capacityDivHeight {
  height: 330px;
}
.detailscheckBlock {
  height: 50px;
}
.divDetailsHeight {
  height: 40px;
  padding-left: 15px;
  padding-top: 10px;
}
.form-control {
  height: calc(1em + 0.75rem + 1px) !important;
}
.custom-select {
  height: calc(1em + 0.75rem + 5px) !important;
}
.tableSize {
  border-width: 0px !important;
}

/* .SelectColor button.primary.outline.selected, button.primary.outline:active { */
se-button.SelectColor > button.hasChild small outline primary selected {
  color: #fff;
  background-color: #000000 !important;
}
.DayPicker-Day {
  display: table-cell;
  padding: 0 !important;
  border-radius: 50%;
  vertical-align: middle;
  text-align: center;
  cursor: pointer;
  z-index: 9999;
}
.DayPicker-Months {
  /* position:absolute !important; */
  z-index: 9999;
}
.DayPicker-wrapper {
  font-size: 12px;
}
.DayPickerInput input {
  width: 100px;
}
.DayPickerInput input:focus {
  outline: none !important;
  border: 2px solid #1167b1 !important;
}
.DayPickerInput {
  position: relative;
  display: inline-block;
  border-radius: 6px;
}
.DayPickerInput::after {
  position: absolute;
  content: url("../images/arrow2_down.png");
  top: 0px;
  right: -8px;
  height: 25px;
  width: 32px;
  cursor: pointer;
}

.col-form-label {
  padding-top: 0 !important;
}
.graphtextColor {
  color: #0087cd;
  font-weight: 700;
}
.graph1textColor {
  color: #e47f00;
  font-weight: 700;
}
.graph2textColor {
  color: #3dcd58;
  font-weight: 700;
}
.commonDash {
  width: 25px;
  height: 0px;
  display: inline-block;
}
.commonDash2 {
  width: 25px;
  height: 0px;
  float: left;
  margin-top: 3px;
}
.displayUnits {
  font-size: 12px;
  position: absolute;
  bottom: 0px;
  right: 8px;
  margin: -5px;
}
.dash {
  border: 0.99px solid #0087cd;
  width: 25px;
  height: 0px;
}
.dash1 {
  border: 0.99px solid #e47f00;
  width: 25px;
  height: 0px;
}
.dash2 {
  border: 0.99px solid #3dcd58;
  width: 25px;
  height: 0px;
}
.dash3 {
  border: 0.99px solid #ffb754;
  width: 25px;
  height: 0px;
}
.dash4 {
  border: 0.99px solid #626469;
  width: 25px;
  height: 0px;
}
.dash5 {
  border: 0.1px solid #ffd100;
  width: 25px;
  height: 0px;
}
.totalAssestsDash {
  border: 0.1px solid #e47f00;
  width: 25px;
  height: 0px;
}

.top-buffer {
  margin-top: -10px;
}
.pleft5 {
  padding-left: 5px;
}

.loading-spin {
  display: inline-block;
  position: relative;
  top: 5px;
  height: 150px;
  -webkit-animation: generate-report-btn 2s linear infinite;
  -moz-animation: generate-report-btn 2s linear infinite;
  animation: generate-report-btn 2s linear infinite;
}
@-moz-keyframes generate-report-btn {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes generate-report-btn {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes generate-report-btn {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.createUserWidgetHeight {
  height: 560px;
}
.AgroAssettHeight {
  height: 126px;
}

.unAcknowledge {
  border: 0px;
  display: inline-block;
  padding: 0px 0px;
  height: 20px;
  width: 100px;
  font-size: 14px;
  color: #ffffff;
  line-height: 7px;
  /* border-radius: 16px; */
  background-color: #cbcbcb;
}

.acknowledge {
  border: 0px;
  display: inline-block;
  padding: 0px 0px;
  height: 20px;
  width: 100px;
  font-size: 14px;
  color: #ffffff;
  line-height: 7px;
  /* border-radius: 16px; */
  background-color: #626469;
}
.InactiveButton {
  border: 1px solid #3dcd58;
  cursor: pointer;
  outline: 0px !important;
  width: 140px;
  height: 32px;
  text-align: center;
  border-radius: 4px 0 0 4px;
  color: #3dcd58;
  text-decoration: none;
  display: inline-block;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 0px;
}
.ActiveButton {
  background-color: #3dcd58;
  outline: 0px !important;
  cursor: pointer;
  border: 1px #3dcd58;
  width: 140px;
  height: 32px;
  text-align: center;
  border-radius: 4px 0 0 4px;
  color: #ffffff;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 0px;
}
.historyInactiveButton {
  border: 1px solid #3dcd58;
  cursor: pointer;
  outline: 0px !important;
  width: 140px;
  height: 32px;
  text-align: center;
  border-radius: 0 4px 4px 0;
  color: #3dcd58;
  text-decoration: none;
  display: inline-block;
  background-color: #ffffff;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 0px;
}
.histortActiveButton {
  background-color: #3dcd58;
  outline: 0px !important;
  cursor: pointer;
  border: 1px #3dcd58;
  width: 140px;
  height: 32px;
  text-align: center;
  border-radius: 0 4px 4px 0;
  color: #ffffff;
  font-size: 16px;
  font-weight: lighter;
  letter-spacing: 0px;
}
.chipStatus {
  display: inline-block;
  height: 24px;
  width: 90px;
  /* font-size: 14px; */
  color: #626469;
  line-height: 23px;
  border-radius: 16px;
  background-color: #f7f7f7;
}
.chip {
  display: inline-block;
  padding: 1px 12px;
  height: 24px;
  width: 90px;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  border-radius: 16px;
  background-color: #505559;
}
.chipSelect {
  display: inline-block;
  padding: 1px 12px;
  height: 24px;
  width: 90px;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  border-radius: 16px;
  background-color: #9fa0a4;
}
.lowchipSelect {
  display: inline-block;
  padding: 1px 12px;
  height: 24px;
  width: 90px;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  border-radius: 16px;
  background-color: #9fa0a4;
}

.lowchip {
  display: inline-block;
  padding: 1px 12px;
  height: 24px;
  width: 90px;
  font-size: 16px;
  color: #ffffff;
  line-height: 22px;
  border-radius: 16px;
  background-color: #505559;
}
.lowchipSelect img {
  float: left;
  margin: 0px 0 -1px -12px;
  height: 22px;
  color: #ffffff;
  width: 22px;
  border-radius: 50%;
}
.chipSelect img {
  float: left;
  margin: -2px 0 -23px -12px;
  height: 25px;
  color: #ffffff;
  width: 25px;
  border-radius: 50%;
}

.chip img {
  float: left;
  margin: -2px 0 -23px -12px;
  height: 25px;
  color: #ffffff;
  width: 25px;
  border-radius: 50%;
}
.lowchip img {
  float: left;
  margin: -1px 0 0px -11px;
  height: 23px;
  color: #ffffff;
  width: 23px;
  border-radius: 50%;
}
.bs-example tbody tr:nth-of-type(even) {
  background-color: #f7f7f7;
}
.bs-example thead {
  background-color: #f7f7f7;
}

.hasTooltip div {
  display: none;
  color: #333333;
  word-break: break-all;
  text-decoration: none;
  padding: 3px 6px;
  z-index: 999999;
  border: none;
}

.hasTooltip:hover div {
  display: block;
  position: absolute;
  background-color: #fff;
  margin: -14px 0px 0px 46px;
  z-index: 999999;
  border: none;
  font-size: 13px;
  line-height: 20px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.55);
}

.pagination {
  display: inline-block;
  padding-left: 0;
  /* margin: 10px 0; */
  border-radius: 4px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  margin-left: 0;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover,
.pagination > .disabled > span,
.pagination > .disabled > span:focus,
.pagination > .disabled > span:hover {
  color: #777;
  background-color: #fff;
  border-color: #ddd;
  cursor: not-allowed;
}
.pagination > li > a,
.pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: #333333;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}
.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover,
.pagination > .active > span,
.pagination > .active > span:focus,
.pagination > .active > span:hover {
  z-index: 3;
  color: #fff;
  background-color: #3dcd58;
  border-color: #3dcd58;
  cursor: default;
}

.createBlockSize {
  display: flex;
  flex-wrap: wrap;
}
.paddingLeft300 {
  padding-left: 210px;
}

.form-control:focus {
  border-color: #626469 !important;
  outline: none !important;
  box-shadow: 0 0 3px #626469 !important;
}

.se-label {
  font-size: 14px;
  margin: 4px 0 4px;
  display: block;
  line-height: 20px;
}

.m-field-wrapper {
  padding: 0px 4px !important;
}

/* .se-form-control{display:block; font-size:13px; padding:4px 6px !important; line-height:28px; height: 28px;} */
.se-form-control-textArea {
  display: block;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  padding: 4px 6px;
  border-radius: 4px;
}
.se-form-control-textArea:focus {
  border-color: #626469;
  outline: none;
  box-shadow: 0 0 3px #626469;
}
.se-form-control-textArea.se-form-error {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dc0a0a;
  box-shadow: 0 0 3px #dc0a0a;
  border-radius: 3px;
  opacity: 1;
}
.se-form-control-textArea.se-form-success {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3dcd58;
  box-shadow: 0 0 3px #3dcd58;
  border-radius: 3px;
  opacity: 1;
}
.se-form-control {
  display: block;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  padding: 4px 6px;
  line-height: 28px;
  border-radius: 4px;
  height: 28px;
}
.se-inline-form .se-form-control {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  min-width: 240px;
}
.se-form-control:focus {
  border-color: #626469;
  outline: none;
  box-shadow: 0 0 3px #626469;
  height: 28px;
}
.se-form-control.se-form-error {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #dc0a0a;
  box-shadow: 0 0 3px #dc0a0a;
  border-radius: 3px;
  opacity: 1;
  height: 28px;
}
.se-form-control.se-form-success {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #3dcd58;
  box-shadow: 0 0 3px #3dcd58;
  border-radius: 3px;
  opacity: 1;
  height: 28px;
}

.paddingLeft200 {
  padding-left: 200px;
}
.paddingLeft150 {
  padding-left: 150px;
}

/* .se-form-card {
  display: none;
  border: 1px solid #e6e6e6;
  font-size: 14px;
} */

.detailsAdd {
  display: inline-block;
  /* padding: 0 8px !important; */
  width: 50px;
  line-height: 16px;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  /* font-weight: bold; */
  cursor: pointer;
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #ccc;
}
/* Notification Overview Related code*/
/* .notificationList{
  height: 38px;
  line-height: 15px;
} */
.font-weight-100 {
  font-weight: lighter;
}
.lowPriority {
  height: 18px;
  width: 18px;
  color: white;
  font-size: 12px;
  background-color: #3dcd58;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.highPriority {
  height: 18px;
  width: 18px;
  color: white;
  font-size: 12px;
  background-color: #dc0a0a;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.mediumPriority {
  height: 18px;
  width: 18px;
  color: white;
  font-size: 12px;
  background-color: #ffd100;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.Information {
  height: 18px;
  width: 18px;
  color: white;
  font-size: 12px;
  background-color: #42b4e6;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.input-number {
  width: 26px !important;
  padding: 0 8px !important;
  vertical-align: top;
  text-align: left;
  outline: none;
}
.input-number,
.input-number-decrement,
.input-number-increment {
  border: 1px solid #ccc;
  height: 20px;
  user-select: none;
}
.input-number-decrement,
.input-number-increment {
  display: inline-block;
  padding: 0 4px !important;
  width: 15px;
  line-height: 16px;
  background: #f1f1f1;
  color: #444;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.input-number-decrement {
  border-right: none;
  border-radius: 4px 0 0 4px;
}
.input-number-increment {
  border-left: none;
  border-radius: 0 4px 4px 0;
}

.report-period-alignment {
  padding-left: 35%;
}
@media (min-width: 1365px) {
  .pop-window-height {
    min-height: 425px;
  }
}
@media (min-width: 1536px) {
  .pop-window-height {
    min-height: 525px;
  }
}

.create-report se-form-field select {
  width: 133%;
  height: 33px;
  padding: 5px;
  padding-left: 10px;
  font-size: 16px;
}
.create-report se-form-field span {
  width: 45% !important;
}
.EnergyConsumed {
  background-color: #e6e6e6;
  outline: 0px !important;
  border: 1px #e6e6e6;
  padding-left: 10px;
  padding-right: 10px;
  width: 150px;
  height: 32px;
  text-align: center;
  border-radius: 4px 4px 4px 4px;
  color: #333333;
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: 0px;
}
.userProfileHeader1Height {
  height: 63px;
}
.userProfileHeader2Height {
  height: 46px;
}

.LineheightOver {
  line-height: 15px;
}
.textItemAlignCenter {
  text-align: center;
  vertical-align: middle;
}
canvas#race {
  position: relative;
  z-index: 1;
}

/* CSS styles used by custom popup template */
.customInfobox {
  max-width: 40px;
  padding: 2px;
  font-size: 8px;
}

.customInfobox .name {
  font-size: 8px;
  font-weight: bold;
  margin-bottom: 1px;
}

.link_txt:hover {
  text-decoration: underline;
  color: #0087cd;
}

.dot {
  height: 5px;
  width: 5px;
  background-color: #333;
  border-radius: 50%;
  display: inline-block;
}

/* User Management styles */
.clsGreenHdr {
  color: #3dcd58;
  font: normal normal 700 18px Nunito;
}

.clsSpnUserStatus {
  color: #0f81b3;
  font: normal normal normal 14px Nunito;
  cursor: pointer;
}

.clsSpnUserStatusCount {
  color: #0f81b3;
  font: normal normal 700 14px Nunito;
}
.clsContainer {
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
  min-height: 600px;
  padding-right: 3em;
  padding-left: 3em;
}

.clsSearchControl {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 0.5px solid #9f9f9f;
  border-radius: 3px;
  opacity: 1;
}

.clsGreenHdr {
  text-align: left;
  letter-spacing: 0px;
  color: #3dcd58;
  opacity: 1;
  font: normal normal bold 18px Nunito;
}

.clsSpnUserStatus {
  text-align: left;
  letter-spacing: 0px;
  color: #0f81b3;
  opacity: 1;
  font: normal normal normal 14px Nunito;
  cursor: pointer;
}

.clsSpnUserStatusCount {
  text-align: left;
  letter-spacing: 0px;
  color: #0f81b3;
  opacity: 1;
  font: normal normal bold 14px Nunito;
}

.clsHomeMenu {
  text-align: left;
  font: normal normal normal 18px/24px Nunito;
  letter-spacing: 0px;
  color: #a6a6a6;
  opacity: 1;
  cursor: pointer;
  margin-right: 0.5em;
}

.clsSpanDivider {
  color: #5c5c5c;
  opacity: 1;
  font: normal normal bold 14px Nunito;
}

.clsUserCard {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 6px #a1a1a140;
  border-radius: 5px;
  opacity: 1;
  margin-top: 1em;
}

.clsUserCardHdr {
  text-align: center;
  font: normal normal normal 14px/19px Nunito;
  letter-spacing: 0px;
  color: #a1a1a1;
  opacity: 1;
  margin-bottom: 0.5em;
}

.clsUserCardDet {
  text-align: center;
  font: normal normal bold 14px/19px Nunito;
  letter-spacing: 0px;
  color: #1c1c1c;
  opacity: 1;
}

.clsNoUserCard {
  text-align: center;
  font: normal normal bold 14px/19px Nunito;
  letter-spacing: 0px;
  color: red;
  opacity: 1;
}

.clsDivider {
  padding-left: 1em;
  padding-right: 1em;
}

.clsRoleOption {
  border: 0.30000001192092896px solid #707070;
  border-radius: 5px;
  opacity: 1;
  margin-left: 0.5em;
  margin-right: 2em;
  height: 2.5em;
  width: 13%;
  padding-left: 1%;
  font: normal normal bold 14px Nunito;
}

.clsRoleOption option {
  text-align: left;
  font: normal normal normal 14px/19px Nunito;
  letter-spacing: 0px;
  color: #1b1b1b;
  opacity: 1;
}

.overme {
  width: 250px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

nav a:hover {
  color: none !important;
}

.trStyles{
  height: 45px;
}

.validationMsg{
  margin-top: 12px;
  margin-left: -55px;
}
.PhoneInputInput{
  height: 33.5px;
  width: 50px;
  font-size: 12px;
}
