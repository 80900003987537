/* .chartjs-render-monitor{
    display: block;
    height: 90px !important;
    width: 152px !important;
} */

#chartjs-genderdiversity{
    display: block;
    height: 90px !important;
    width: 152px !important;
}