.mycoolstyle {
  color: red;
}
.flex {
  flex: 1
}
.d-flex {
  display: flex;
}

.button-row {
  padding: 10px;
  se-button {
    margin: 4px;
  }
}

.LineheightOver{
  @media only screen and (min-width: 1201px) and (max-width: 1280px) {
    padding-left: 2rem;
  }
  @media only screen and (min-width: 1281px) and (max-width: 1600px) {
    padding-left: 2rem;
  }

}